// extracted by mini-css-extract-plugin
export var appicon1 = "layout-module--appicon1--bb490";
export var appicon2 = "layout-module--appicon2--377f5";
export var appicon3 = "layout-module--appicon3--6a64f";
export var appicons = "layout-module--appicons--cd776";
export var content = "layout-module--content--2bfc1";
export var copyright = "layout-module--copyright--ee89b";
export var cta = "layout-module--cta--af9fd";
export var footerlinks = "layout-module--footerlinks--f8e74";
export var footertextappercased = "layout-module--footertextappercased--aff2f";
export var footertextbox = "layout-module--footertextbox--9cc3c";
export var footertextregular = "layout-module--footertextregular--d09e0";
export var footertextsdk = "layout-module--footertextsdk--45f1c";
export var highlight = "layout-module--highlight--33fb0";
export var lastcolumn = "layout-module--lastcolumn--ecb02";
export var navbar = "layout-module--navbar--d1c06";
export var navbaritem = "layout-module--navbaritem--4f53e";
export var navbarlogo = "layout-module--navbarlogo--94bf7";