import * as React from 'react'
import * as styles from "./contact.module.css"
import Card from './card'
import HighlightSpan from './highlightspan';

function handleSubmit(event) {
    event.preventDefault();
}

const Contact = () => {
    var url = '';

    const changeText = (event) => {
        url = event.target.value;
    };

    const handleSubmit = (event) => {
        const content = "RSS feed URL address: " + url;
        window.location.href = "mailto:hello@appercased.com?body=" + encodeURIComponent(content);
        event.preventDefault();
    }

    return (
        <div id="preview" className={styles.contactsection}>
            <div className={styles.box}>
                <Card>
                    <div className={styles.innerbox}>
                        <h3><HighlightSpan>Get FREE preview of your app!</HighlightSpan></h3>
                        <p>If you have RSS channel you can send it to us to get free preview screenshots of your Apple Watch® app!</p>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.contactform}>
                                <label >Enter your RSS channel URL:</label><br></br>
                                <div className={styles.textfield}>
                                    <input type="text" name="name" onChange={changeText} placeholder="https://appercased.com/rss.xml" /><br></br>
                                </div>
                                <input type="submit" value="Continue" />
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default Contact