import * as React from 'react'
import * as styles from "./complications.module.css"
import { StaticImage } from 'gatsby-plugin-image'
import TextSection from './textsection'
import UnderlineSpan from './underlinespan'
import HighlightSpan from './highlightspan'
import Content from './content'

const Complications = () => {
    return (
        <div className={styles.complicationssection}>
            <div className={styles.complications}>
                <Content>
                    <div className={styles.complicationsbox}>
                        <TextSection useBottomPadding={false}>
                            <h3><HighlightSpan>Breaking news at glance</HighlightSpan></h3>
                            <p>Native app is just one of many things that makes WatchNews SDK powerful - unlock full potential with custom complications which allows you to show latest news right on your readers Watch Faces!</p>
                        </TextSection>
                        <div className={styles.complicationsimages}>
                            <StaticImage className={styles.hideonmobile} src="../images/comp2.png" objectFit="contain" height={300} alt="WatchNews SDK preview screenshot with Watch Face complication 1" />
                            <StaticImage className={styles.maincomplication} src="../images/comp1.png" objectFit="contain" height={400} alt="WatchNews SDK preview screenshot with Watch Face complication 2" />
                            <StaticImage className={styles.hideonmobile} src="../images/comp3.png" objectFit="contain" height={300} alt="WatchNews SDK preview screenshot with Watch Face complication 3" />
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    )
}

export default Complications