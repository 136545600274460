import * as React from 'react'
import * as styles from "./inline.module.css"

const Inline = ({ children }) => {
    return (
        <div className={styles.inline}>
            {children}
        </div>
    )
}

export default Inline