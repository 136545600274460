import * as React from 'react'
import * as styles from "./content.module.css"

const Content = ({ children }) => {
    return (
        <div className={styles.bodycontent}>
            {children}
        </div>
    )
}

export default Content