import * as React from 'react'
import * as styles from "./grow.module.css"

const Grow = ({ children }) => {
    return (
        <div className={styles.grow}>
            {children}
        </div>
    )
}

export default Grow