import * as React from 'react'
import { Link } from 'gatsby'
import * as styles from "./layout.module.css"
import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Layout = ({ useSafeArea = true, children }) => {
    const iconHeight = 30;
    return (
        <div>
            <div className={styles.navbar}>
                <div className={styles.navbarlogo}>
                    <StaticImage src="../images/logo.png" objectFit="contain" width={45} alt="APPERCASED LOGO" />
                </div>
                <AnchorLink to="/sdk/watchnews#preview" stripHash={true}>
                    <div className={styles.navbaritem}>
                        <div className={styles.cta}>
                            <p>Free preview</p>
                        </div>
                    </div>
                </AnchorLink>
                <div className={styles.navbaritem}>
                    <p><a href="mailto: hello@appercased.com">Contact us</a></p>
                </div>
                <AnchorLink to="/sdk/watchnews#benefits" stripHash={true}>
                    <div className={styles.navbaritem}>
                        <p>Benefits</p>
                    </div>
                </AnchorLink>
                <AnchorLink to="/sdk/watchnews#customization" stripHash={true}>
                    <div className={styles.navbaritem}>
                        <p>Customisation</p>
                    </div>
                </AnchorLink>
                <AnchorLink to="/sdk/watchnews#about" stripHash={true}>
                    <div className={styles.navbaritem}>
                        <p>About</p>
                    </div>
                </AnchorLink>

            </div>
            <div className={styles.content} style={{ marginTop: useSafeArea ? iconHeight + 10 : 0 }}>
                {children}
            </div>
            <footer>
                <div className={styles.footertextbox}>
                    <div className={styles.footercolumn}>
                        <StaticImage src="../images/banner.png" objectFit="contain" height={35} alt="APPERCASED LOGO" />
                        <p className={styles.highlight}>Krzysztof Rózga Mobile Apps</p>
                        <p><span className={styles.highlight}>E-mail:</span> <a href="mailto: hello@appercased.com">hello@appercased.com</a></p>
                        <p><span className={styles.highlight}>Address:</span> Komornicka 31/2,</p>
                        <p>62-069 Dąbrówka, Poland</p>
                        <p><span className={styles.highlight}>EU VAT id:</span> PL7792503185</p>
                    </div>
                    <div className={styles.footercolumn}>
                        <div className={styles.footerlinks}>
                            <h2>Links</h2>
                            <p><a href="/">About APPERCASED</a></p>
                            <p><a href="/#projects">Our Portfolio</a></p>
                            <p><a href="mailto: hello@appercased.com">Contact us</a></p>
                            <p><a href={"/privacy"}>Privacy Policy</a></p>
                        </div>
                    </div>
                    <div className={styles.lastcolumn}>
                        <div className={styles.footercolumn}>
                            <h2>Our products</h2>
                            <div className={styles.appicons}>
                                <a href="https://watchplus.app/">
                                    <div className={styles.appicon1} />
                                </a>
                                <a href="https://apps.apple.com/us/app/snapcards-learn-vocabulary/id1555335044">
                                    <div className={styles.appicon2} />
                                </a>
                                <a href="/sdk/watchnews">
                                    <div className={styles.appicon3} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <p>APPERCASED & WatchNews SDK Copyright © 2023 Krzysztof Rózga Mobile Apps</p>
                </div>
            </footer>
        </div>
    )
}

export default Layout