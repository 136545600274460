import * as React from 'react'
import * as styles from "./centerhorizontally.module.css"

const CenterHorizontally = ({ children }) => {
    return (
        <div className={styles.center}>
            {children}
        </div>
    )
}

export default CenterHorizontally