import * as React from 'react'
import * as styles from "./customize.module.css"
import Inline from './inline'
import { StaticImage } from 'gatsby-plugin-image'
import Grow from './grow'
import CenterHorizontally from './centerhorizontally'
import TextSection from './textsection'
import UnderlineSpan from './underlinespan'
import Content from './content'

const Customize = () => {
    return (
        <div id="customization">
            <Content>
                <TextSection useBottomPadding={false}>
                    <h3>Make it yours with <UnderlineSpan>full customisation</UnderlineSpan></h3>
                    <p>Design your own Apple Watch® app style to match your iOS® mobile app and emphasise your brand’s identity. Change colors, fonts, character size, layout, corners, icons, loading indicators and more!</p>
                </TextSection>
            </Content>
            <CenterHorizontally>
                <div className={styles.customizemobile}>
                    <StaticImage src="../images/custom0.png" objectFit="contain" alt="Example code for app styling" />
                    <StaticImage src="../images/custom1.png" objectFit="contain" alt="Example app created with WatchNews SDK with card style" />
                    <StaticImage src="../images/custom2.png" objectFit="contain" alt="Example app created with WatchNews SDK with thumbnail cell style" />
                    <StaticImage src="../images/custom3.png" objectFit="contain" alt="Example app created with WatchNews SDK with simple cell style" />
                </div>
                <div className={styles.customizedesktop}>
                    <div>
                        <Inline>
                            <div>
                                <Grow>
                                    <StaticImage src="../images/custom1.png" objectFit="contain" alt="Example app created with WatchNews SDK with card style" />
                                </Grow>
                                <Grow>
                                    <StaticImage src="../images/custom3.png" objectFit="contain" alt="Example app created with WatchNews SDK with simple cell style" />
                                </Grow>
                            </div>
                            <div className={styles.leftcol}>
                                <Grow>
                                    <StaticImage src="../images/custom0.png" objectFit="contain" alt="Example code for app styling" />
                                </Grow>
                                <Grow>
                                    <StaticImage src="../images/custom2.png" objectFit="contain" alt="Example app created with WatchNews SDK with thumbnail cell style" />
                                </Grow>
                            </div>
                        </Inline>
                    </div>
                </div>
            </CenterHorizontally>
        </div>
    )
}

export default Customize