import * as React from 'react'
import * as styles from "./textsection.module.css"

const TextSection = ({ useBottomPadding = true, children }) => {
    return (
        <div className={styles.section}>
            <div className={useBottomPadding ? styles.bottompadding : null}>
                {children}
            </div>
        </div>
    )
}

export default TextSection