import * as React from 'react'
import * as styles from "./integration.module.css"
import { StaticImage } from 'gatsby-plugin-image'
import TextSection from './textsection'
import UnderlineSpan from './underlinespan'
import Content from './content'

const Integration = () => {
    return (
        <Content>
            <TextSection useBottomPadding={false}>
                <h3>How to <UnderlineSpan>integrate?</UnderlineSpan></h3>
                <p>To integrate simply add WatchKit SDK framework to your Project then select one of many ways to connect data source.</p>
            </TextSection>
            <div className={styles.cards}>
                <div className={styles.roundedcard}>
                    <span className="material-icons">rss_feed</span>
                    <h3>RSS channel</h3>
                </div>
                <div className={styles.roundedcard}>
                    <span className="material-icons">language</span>
                    <h3>Network call</h3>
                </div>
                <div className={styles.roundedcard}>
                    <span className="material-icons">install_mobile</span>
                    <h3>Mirror phone</h3>
                </div>
            </div>
        </Content>
    )
}

export default Integration