import * as React from 'react'
import * as styles from "./about.module.css"
import Inline from './inline'
import { StaticImage } from 'gatsby-plugin-image'
import MaxWidth from './maxwidth'
import UnderlineSpan from './underlinespan'
import Content from './content'

const About = () => {
    return (
        <div id="about">
            <Content>
                <div className={styles.about}>
                    <div className={styles.aboutcontent}>
                        <h2>Create your own <UnderlineSpan>News app</UnderlineSpan> for Apple Watch® in minutes!</h2>
                    </div>
                    <div className={styles.screenshots}>
                        <StaticImage className={styles.hideonmobile} src="../images/about1.png" objectFit="contain" height={340} alt="WatchNews SDK preview screenshot with example news feed" />
                        <div className={styles.primaryscreenshot}>
                            <StaticImage className={styles.maincomplication} src="../images/about2.png" objectFit="contain" height={400} alt="WatchNews SDK preview screenshot with example news reader" />
                        </div>
                        <div className={styles.alernativescreenshot}>
                            <StaticImage className={styles.maincomplication} src="../images/about2small.png" objectFit="contain" height={340} alt="WatchNews SDK preview screenshot with example news reader" />
                        </div>
                        <StaticImage className={styles.hideonmobile} src="../images/about3.png" objectFit="contain" height={340} alt="WatchNews SDK preview screenshot with example Watch Face complication" />   
                    </div>
                    <div className={styles.aboutcontent}>
                        <p>WatchNews SDK is a tool dedicated to news apps owners which allows to easily embed fully customisable Apple Watch® app within existing iOS® application.</p>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default About