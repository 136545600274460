import * as React from 'react'
import * as styles from "./benefits.module.css"
import { StaticImage } from 'gatsby-plugin-image'
import TextSection from './textsection'
import UnderlineSpan from './underlinespan'
import HighlightSpan from './highlightspan'
import Content from './content'

const Benefits = () => {
    return (
        <div className={styles.benefits}>
            <Content>
                <div id="benefits" className={styles.benefitsbox}>
                    <TextSection useBottomPadding={false}>
                        <h3><HighlightSpan>How do you benefit?</HighlightSpan></h3>
                        <div className={styles.benefit}>
                            <div className={styles.benefitimg}>
                                <StaticImage src="../images/engage.png" objectFit="contain" alt="Growth icon" width={55} />
                            </div>
                            <div className={styles.benefittext}>
                                <h4>Increase user engagement</h4>
                                <p>With wearable devices constant increase in popularity offer your readers a way to stay tuned to your news at a glance at the wrist.</p>
                            </div>
                        </div>
                        <div className={styles.benefit}>
                            <div className={styles.benefitimg}>
                                <StaticImage src="../images/readyapp.png" objectFit="contain" alt="Rocket icon" width={55} />
                            </div>
                            <div className={styles.benefittext}>
                                <h4>Production ready app in minutes</h4>
                                <p>Drag-and-drop the SDK, initialise with RSS channel url - done. Your app is ready for release!</p>
                            </div>
                        </div>
                        <div className={styles.benefit}>
                            <div className={styles.benefitimg}>
                                <StaticImage src="../images/custom.png" objectFit="contain" alt="Stars icon" width={55} />
                            </div>
                            <div className={styles.benefittext}>
                                <h4>Extensive Customisation</h4>
                                <p>Advanced configuration & theming options allows you to create truly unique app.</p>
                            </div>
                        </div>
                    </TextSection>
                </div>
            </Content>
        </div>
    )
}

export default Benefits