import * as React from 'react'
import * as styles from "./underlinespan.module.css"

const UnderlineSpan = ({ children }) => {
    return (
        <span className={styles.underline}>
            {children}
        </span>
    )
}

export default UnderlineSpan