import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import About from "../../components/about"
import Customize from "../../components/customize"
import Benefits from "../../components/benefits"
import Acessibility from "../../components/accessibility"
import Contact from "../../components/contact"
import Integration from "../../components/integration"
import Timeline from "../../components/timeline"
import Layout from "../../components/layout"
import Complications from "../../components/complications"
import Setup from "../../components/setup"


const IndexPage = () => {
    return (
        <SEO pageTitle="WatchNews SDK | APPERCASED" pageDescription="WatchNews SDK is a tool dedicated to news apps owners which allows to easily embed fully customisable Apple Watch® app within existing iOS® application">
            <Layout useSafeArea={false}>
                <Hero />
                <About />
                <Setup />
                <Customize />
                <Benefits />
                <Acessibility />
                <Complications />
                <Integration />
                <Contact />
                <Timeline />
            </Layout>
        </SEO>
    )
}

export default IndexPage
