import * as React from 'react'
import * as styles from "./timeline.module.css"
import UnderlineSpan from './underlinespan'
import TextSection from './textsection'
import CenterHorizontally from './centerhorizontally'
import Content from './content'

const Timeline = () => {
    const handleSubmit = (event) => {
        window.location.href = "mailto:hello@appercased.com";
        event.preventDefault();
    }

    return (
        <Content>
            <div className={styles.timelinesection}>
                <TextSection>
                    <CenterHorizontally>
                        <h3>Any questions? <UnderlineSpan>Let's talk!</UnderlineSpan></h3>
                        <form onSubmit={handleSubmit}>
                            <input type="submit" value="Contact us" />
                        </form>
                    </CenterHorizontally>
                </TextSection>
            </div>
        </Content>
    )
}

function Entry({ entryNo, title, description }) {
    return (
        <div className={styles.rowentry}>

            <h4><span className={styles.highlight}>{entryNo}</span> {title}</h4>
            <div className={styles.rowbody}>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default Timeline