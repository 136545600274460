import * as React from 'react'
import * as styles from "./hero.module.css"
import { StaticImage } from 'gatsby-plugin-image'
import HighlightSpan from './highlightspan'
import UnderlineSpan from './underlinespan'
import Grow from './grow'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Content from './content'

const Hero = () => {
    return (
        <div className={styles.hero}>
            <div className={styles.herocontent}>
                <div className={styles.headers}>
                    <h1><HighlightSpan>Deliver news right to </HighlightSpan><br /><HighlightSpan>your readers' wrists</HighlightSpan></h1>
                    <h2>Increase user engagement with plug and play <HighlightSpan>Apple Watch® app</HighlightSpan></h2>
                    <div className={styles.learnmore}>
                        <AnchorLink to="/sdk/watchnews#about" stripHash={true}>
                            <Grow>
                                <div className={styles.learnmorebutton}>
                                    <div className={styles.arrow}>
                                        <StaticImage src="../images/arrow.png" objectFit="contain" height={40} alt="Scroll down arrow icon" />
                                    </div>

                                    <p><HighlightSpan>Learn more</HighlightSpan></p>
                                </div>
                            </Grow>
                        </AnchorLink>
                    </div>
                </div>
                <div className={styles.largeheroimage}>
                    <StaticImage src="../images/hero2.png" objectFit="contain" width={750} alt="WatchNews SDK hero image presenting customization options" />
                </div>
                <div className={styles.smallheroimage}>
                    <StaticImage src="../images/hero3.png" objectFit="contain" height={500} alt="WatchNews SDK hero image presenting customization options" />
                </div>
            </div>
        </div>

    )
}

export default Hero