import * as React from 'react'
import * as styles from "./setup.module.css"
import Inline from './inline'
import { StaticImage } from 'gatsby-plugin-image'
import HighlightSpan from './highlightspan'

const Setup = () => {
    return (
        <div className={styles.setupsection}>
            <div className={styles.setup}>
                <h3><HighlightSpan>How to add watchOS® app?</HighlightSpan></h3>
                <div className={styles.cellssection}>
                    <div className={styles.setupcells}>
                        <SetupCell title={"Drag & Drop SDK"} description={"Add SDK to your existing iOS® project in Xcode"}>
                            <StaticImage src="../images/addsdk.png" objectFit="contain" alt="Drag & Drop icon" width={140} />
                        </SetupCell>
                        <SetupCell title={"Link News Feed"} description={"Use RSS channel or implement your own custom logic for data access"}>
                            <StaticImage src="../images/syncicon.png" objectFit="contain" alt="Link RSS feed icon" width={140} />
                        </SetupCell>
                        <SetupCell title={"Apply Theme"} description={"Configure SDK with your own branding, fonts & app capabilities"}>
                            <StaticImage src="../images/designicon.png" objectFit="contain" alt="Design theme icon" width={140} />
                        </SetupCell>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SetupCell = ({title, description, children}) => {
    return (
        <div className={styles.aboutcell}>
                    {children}

                <div className={styles.abouttext}>
                    <h4>{title}</h4>
                    <p>{description}</p>
                </div>
        </div>
    ) 
}

export default Setup