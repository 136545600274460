import * as React from 'react'
import * as styles from "./accessibility.module.css"
import Inline from './inline'
import { StaticImage } from 'gatsby-plugin-image'
import MaxWidth from './maxwidth'
import UnderlineSpan from './underlinespan'
import TextSection from './textsection'
import CenterHorizontally from './centerhorizontally'
import Content from './content'

const Acessibility = () => {
    return (
        <Content>
            <div className={styles.accessibility}>
                <TextSection>
                    <h3><UnderlineSpan>Accessibility</UnderlineSpan> matters</h3>
                    <p>Accessibility is our foundation not additional feature - we want all readers to be able to use application including visually impaired users.</p>
                    <CenterHorizontally>
                        <StaticImage src="../images/accessibility.png" objectFit="contain" alt="WatchNews SDK app accessibility options" width={380} />
                    </CenterHorizontally>
                </TextSection>
            </div>
        </Content>
    )
}

export default Acessibility