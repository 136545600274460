import * as React from 'react'
import * as styles from "./highlightspan.module.css"

const HighlightSpan = ({ children }) => {
    return (
        <span className={styles.highlight}>
            {children}
        </span>
    )
}

export default HighlightSpan